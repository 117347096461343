<template>
  <div>
    <div>
      <v-row dense>
        <v-col cols="7" class="pa-0 ma-0">
          <div class="d-flex ml-5">
            <v-icon
                :ripple="false"
                id="backBtn"
                color="primary"
                class="pa-1  mr-3 mt-n2"
                @click="closeDrawer()"
            >mdi-chevron-left
            </v-icon>
            <v-img :src="require('@/assets/icons/'+ vitalsType.deviceIcon +'black.svg')" :alt="vitalsType.deviceName" aspect-ratios="1" max-width="20" class="mr-2 mt-4" height="20"> </v-img>
            <span class="mt-4 font-size16 font-weight-bold color-black">{{$i18n.locale === 'en' ? vitalsType.deviceName : vitalsType.deviceNameEsp}}</span>
            <v-checkbox
                :disabled="!periodData"
                v-model="thresholdToggle"
                :ripple="false"
                class="mt-3 ml-5"
                :label="$t('thresholds')"
                @change="toggleThresholds"
            >
            </v-checkbox>
<!--            <v-switch-->
<!--                :ripple="false"-->
<!--                color="#09cb09"-->
<!--                class="ml-auto"-->
<!--            >-->
<!--            </v-switch>-->
          </div>
        </v-col>
        <v-col cols="5" class="pa-0 ma-0">
          <div
              id="InteractiveComponents"
              class="d-flex pa-3 height15 "
          >
            <v-select
                  v-model="period"
                  :ripple="false"
                  :items="getDataFilterTypes"
                  @change="filterData"
                  :item-text="$i18n.locale === 'en' ? 'longDescription' : 'longDescriptionEsp'"
                  hide-details
                  item-value="shortDescription"
                  dense
                  outlined
                  :menu-props="{ offsetY: true }"
              ></v-select>
            <v-btn :disabled="detailedUser.userData.status === 99" class="mx-2 " color="chips" style="text-transform: capitalize; color:white; margin-top:1px;" :ripple="false" elevation="0" @click="synchronizeDrawers('addNote')">+ {{$t('note')}} </v-btn>
          </div>
        </v-col>

      </v-row>

    </div>
    <div>
      <div class="d-flex height45" id="VitalsGraph">
        <v-col  :cols="deviceType === 5  ? '12' : '10'" id="VitalsGraph" :class="{'shrunkCol': switchToggle}" class="VitalsData pa-0 justify-space-between">
          <div class="height80">
            <VitalsGraph
                v-if="periodData"
                ref="vitalsGraph"
                :graphData="measurements"
                :thresholdsValues="thresholds"
                :extreamThreshHolds="extreamThreshHolds"
                :thresholdToggle="thresholdToggle"
                :unitSystem="getUnitSystem"
                :detailsToggle="switchToggle"
            ></VitalsGraph>
            <div v-if="!periodData" class="d-flex flex-column justify-center">
              <v-icon color="dark gray" x-large>mdi-chart-areaspline-variant</v-icon>
              <p class="text-body-1"> {{ $t("no-data-found") }} </p>
              <p class="text-caption gray-color">
                {{ $t( "filter-note" )}}
              </p>
            </div>
          </div>
        </v-col>
        <v-col
            id="VitalsDetails"
            class="VitalsData ml-2 px-1 py-2"
            :cols="deviceType === 5  ? '0' : '2'"
        >
          <div class="responsive-container">

          <div v-for="item in items" :key="item.id"  class="my-3">
            <v-row dense :style="{borderLeft: '8px solid' + item.color}" v-if="deviceType === 3">
              <v-col align-self="center" class=" text-left">
                <v-row dense class="pa-1 font-weight-bold font-size12">{{item.classification}}</v-row>
                  <v-row dense class="pa-1 font-size11 font-weight-medium">{{ item.systolicBloodPressure }}</v-row>
              </v-col>
            </v-row>
            <v-row v-else :style="{borderLeft: '8px solid' + item.color}" dense>
              <v-col  align-self="center" class=" text-left">
                <v-row dense class="pa-1 font-weight-bold font-size12">{{ item.description }}</v-row>
                  <v-row dense class="pa-1 font-size11 font-weight-medium ">{{ item.first_unit }}</v-row>
              </v-col>
            </v-row>
          </div>
          </div>
        </v-col>
      </div>
      <div>
        <v-data-table
            :key="rerender"
            :headers="headers"
            fixed-header
            :height="pageHeight(-190)"
            :items="measurements"
            id="VitalsDataTable"
            item-key="dateTime"
            sort-by="dateTime"
            dense
            :footer-props="footerProps"
            :items-per-page="-1"
        >
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import VitalsGraph from '@/views/Client/components/VitalsGraph.vue';
import { formatterFilter } from '@/utils/luxon-formater';
import { fromTo } from '@/utils/unit-converter.js';
import { mapGetters, mapState } from 'vuex';
export default {
  data () {
    return {
      footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
      thresholdToggle: true,
      switchToggle: true,
      timeLocalization: this.$i18n.locale,
      rerender: 1,
      period: '',
      periodData: false,
    };
  },
  props: {
    vitalsType: null,
    unitSystem: null,
  },
  components: {
    VitalsGraph,
  },
  mounted () {
    this.getDataFilterType();
  },
  computed: {
    ...mapGetters({
      pageHeight: 'pageHeight',
      userSettings: 'authentication/getUserSettings',
}),
    ...mapState({
      detailedUser: (state) => state.users.detailedUser,
      hubs: (state) => state.nosessionhubs.hubs,
      getDataFilterTypes: (state) => state.verifiers.getDataFilterTypes,
    }),
    deviceType () {
      return this.vitalsType.deviceType;
    },

    testHeight () {
      // Adjustments for common resolutions
      if (this.$vuetify.breakpoint.width <= 1280 && this.$vuetify.breakpoint.height <= 720) {
        return '220'; // 1280x720 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1366 && this.$vuetify.breakpoint.height <= 768) {
        return '250'; // 1366x768 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1440 && this.$vuetify.breakpoint.height <= 900) {
        return '320'; // 1440x900 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1600 && this.$vuetify.breakpoint.height <= 900) {
        return '320'; // 1600x900 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1920 && this.$vuetify.breakpoint.height <= 1080) {
        return '420'; // 1920x1080 or lower
      }
      if (this.$vuetify.breakpoint.width <= 1920 && this.$vuetify.breakpoint.height <= 1200) {
        return '450'; // 1920x1200 or lower
      }
      if (this.$vuetify.breakpoint.xl) {
        return '450'; // 2560x1440 or higher
      }
      // Default value for resolutions not specified above
      return '400';
    },
    items () {
      const ICONS = {
        low: 'mdi-thermometer-low',
        normal: 'mdi-thermometer',
        advice: 'mdi-thermometer-plus',
        acceptable: 'mdi-thermometer-alert',
        urgent: 'mdi-thermometer-high',
      };
      const COLORS = [
        '#64B5F6',
        '#43A047',
        '#FFD740',
        '#FF6D00',
        '#DD2C00',
        '#90CAF9',
        '#BBDEFB',
      ];
      let items = [];
      switch (this.vitalsType.deviceType) {
        case 1:
          items = [
            {
              id: 1,
              color: COLORS[0],
              icon: ICONS.low,
              description: this.$t('low'),
              first_unit: (this.userSettings.Units.toLowerCase() === 'metric' ? parseFloat(this.thresholds.MinTemperature).toFixed(1) + '°C ' : fromTo(parseFloat(this.thresholds.MinTemperature), 'C', 'F').toFixed(1) + '°F ') + this.$t('or-less'),
              // {"MinTemperature":"36.10","MaxTemperature":"37.20"}
            },
            {
              id: 2,
              color: COLORS[1],
              icon: ICONS.normal,
              description: this.$t('normal-readings'),
              // first_unit: '(36.5-37.5)°C  (99.5-99.7)°F',
              first_unit: this.userSettings.Units.toLowerCase() === 'metric' ? '(' + parseFloat(this.thresholds.MinTemperature).toFixed(1) + ' - ' + parseFloat(this.thresholds.MaxTemperature).toFixed(1) + ')°C ' : '(' + fromTo(parseFloat(this.thresholds.MinTemperature), 'C', 'F').toFixed(1) + ' - ' + fromTo(parseFloat(this.thresholds.MaxTemperature), 'C', 'F').toFixed(1) + ')°F',
              // {"MinTemperature":"36.10","MaxTemperature":"37.20"}
            },
            {
              id: 3,
              color: COLORS[2],
              icon: ICONS.acceptable,
              description: this.$t('home-monitoring'),
              first_unit: this.userSettings.Units.toLowerCase() === 'metric' ? '(' + parseFloat(this.thresholds.MaxTemperature).toFixed(1) + ' - 38)°C ' : '(' + fromTo(parseFloat(this.thresholds.MaxTemperature), 'C', 'F').toFixed(1) + ' - 100.4)°F',
            },
            {
              id: 4,
              color: COLORS[3],
              icon: ICONS.advice,
              description: this.$t('advice'),
              first_unit: this.userSettings.Units.toLowerCase() === 'metric' ? '(38.1-39)°C' : '(100.58-102.2)°F',
              // first_unit: '(38.1-39)°C  (100.58-102.2)F',
            },
            {
              id: 5,
              color: COLORS[4],
              icon: ICONS.urgent,
              description: this.$t('call-emergency'),
              first_unit: (this.userSettings.Units.toLowerCase() === 'metric' ? '39°C ' : '102.2°F ') + this.$t('or-more'),
            },
          ];
          break;
        case 2:
          items = [
            {
              id: 1,
              color: COLORS[1],
              icon: ICONS.urgent,
              description: this.$t('normal-readings'),
              first_unit: this.thresholds.SpO2 + '%' + this.$t('or-more'),
            },
            {
              id: 2,
              color: COLORS[2],
              icon: ICONS.urgent,
              description: this.$t('home-monitoring'),
              first_unit: '< ' + this.thresholds.SpO2 + '%',
            },
            {
              id: 3,
              color: COLORS[3],
              icon: ICONS.urgent,
              description: this.$t('advice'),
              first_unit: '93-94%',
            },
            {
              id: 4,
              color: COLORS[4],
              icon: ICONS.urgent,
              description: this.$t('call-emergency'),
              first_unit: '92% ' + this.$t('or-less'),
            },
          ];
          break;
        case 3:
          items = [
            {
              id: 1,
              color: COLORS[0],
              icon: ICONS.urgent,
              classification: this.$t('low'),
              systolicBloodPressure: '<(' + this.thresholds.SystolicMin + '/' + this.thresholds.DiastolicMin + ') mmHg',
            },
            {
              id: 2,
              color: COLORS[1],
              icon: ICONS.urgent,
              classification: this.$t('normal'),
              systolicBloodPressure: '(' + this.thresholds.SystolicMin + '/' + this.thresholds.DiastolicMin + ' - ' + this.thresholds.SystolicMax + '/' + this.thresholds.DiastolicMax + ') mmHg',
            },
            {
              id: 3,
              color: COLORS[2],
              icon: ICONS.urgent,
              classification: this.$t('prehypertension'),
              systolicBloodPressure: '>(' + this.thresholds.SystolicMax + '/' + this.thresholds.DiastolicMax + ') mmHg',
            },
            {
              id: 4,
              color: COLORS[3],
              icon: ICONS.urgent,
              classification: this.$t('stage-one-hypertension'),
              systolicBloodPressure: '(140-159/90-99) mmHg',
            },
            {
              id: 5,
              color: COLORS[4],
              icon: ICONS.urgent,
              classification: this.$t('stage-two-hypertension'),
              systolicBloodPressure: '>(160/100) mmHg',
            },
          ];
          break;
        // case 5:
        //   items = [
        //     {
        //       id: 1,
        //       color: COLORS[0],
        //       icon: ICONS.low,
        //       description: this.$t('low'),
        //       first_unit: '< 35°C   < 95.0°F ' + this.$t('or-less'),
        //     },
        //     {
        //       id: 2,
        //       color: COLORS[1],
        //       icon: ICONS.normal,
        //       description: this.$t('normal-readings'),
        //       first_unit: '(36.5-37.5)°C  (99.5-99.7)°F',
        //     },
        //     {
        //       id: 3,
        //       color: COLORS[2],
        //       icon: ICONS.acceptable,
        //       description: this.$t('home-monitoring'),
        //       first_unit: '(37.5-38)°C  (99.7-100.4)°F',
        //     },
        //     {
        //       id: 4,
        //       color: COLORS[3],
        //       icon: ICONS.advice,
        //       description: this.$t('advice'),
        //       first_unit: '(38.1-39)°C  (100.58-102.2)F',
        //     },
        //     {
        //       id: 5,
        //       color: COLORS[4],
        //       icon: ICONS.urgent,
        //       description: this.$t('call-emergency'),
        //       first_unit: '39°C  102.2°F ' + this.$t('or-more'),
        //     },
        //   ];
        //   break;
        case 6:
          items = [
            {
              id: 1,
              color: COLORS[5],
              icon: ICONS.urgent,
              description: this.$t('severe-hypoglycemia'),
              first_unit: this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? '<3 mmol/L' : '<54 mg/dL',
            },
            {
              id: 2,
              color: COLORS[0],
              icon: ICONS.urgent,
              description: this.$t('hypoglycemia'),
              // first_unit: this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? '<3.9 mmol/L' : '<70 mg/dL',
              first_unit: this.userSettings.Concentration.toLowerCase() === 'mmol/l'
                  ? '<' + this.thresholds.BloodSugarLevelMin + 'mmol/L'
                  : '<' + this.thresholds.BloodSugarLevelMin * 18 + 'mg/dL',
            },
            {
              id: 3,
              color: COLORS[1],
              icon: ICONS.urgent,
              description: this.$t('normal'),
              first_unit: this.userSettings.Concentration.toLowerCase() === 'mmol/l'
                  ? '(' + this.thresholds.BloodSugarLevelMin + ' - ' + this.thresholds.BloodSugarLevelMax + ')mmol/L'
                  : '(' + this.thresholds.BloodSugarLevelMin * 18 + ' - ' + this.thresholds.BloodSugarLevelMax * 18 + ') mg/dL',
            },
            {
              id: 4,
              color: COLORS[3],
              icon: ICONS.urgent,
              description: this.$t('hyperglycemia'),
              first_unit: this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? '>6.9 mmol/L before meal\n>10 mmol/L 2h after meal' : '>125 mg/dL before meal\n>180mg/dL 2h after meal',
            },
            {
              id: 5,
              color: COLORS[4],
              icon: ICONS.urgent,
              description: this.$t('diabetes'),
              first_unit: this.userSettings.Concentration.toLowerCase() === 'mmol/l' ? '>7.0 mmol/L before meal\n>7.0 mmol/L 2h after meal' : '>126 mg/dL before meal\n>180mg/dL 2h after meal',
            },
          ];
          break;
        case 7:
          items = [
            {
              id: 1,
              color: COLORS[5],
              icon: ICONS.urgent,
              description: this.$t('optimal'),
              first_unit: '<200 mg/dL  <5.2 mmol/L',
            },
            {
              id: 2,
              color: COLORS[1],
              icon: ICONS.urgent,
              description: this.$t('intermediate'),
              first_unit: '(200-239)mg/dL  (5.3-6.2)mmol/L',
            },
            {
              id: 3,
              color: COLORS[4],
              icon: ICONS.urgent,
              description: this.$t('high'),
              first_unit: '>239 mg/dL  >6.2mmol/L',
            },
          ];
          break;
      }
      return items;
    },
    extreamThreshHolds: {
      get () {
        return JSON.parse(this.vitalsType.deviceDefaultExtremeValuesJson);
      },
      set (newValue) {
        this.vitalsType.deviceDefaultExtremeValuesJson = JSON.stringify(newValue);
      },
    },
    thresholds: {
      get () {
        return JSON.parse(this.vitalsType.deviceDefaultValuesJson);
      },
      set (newValue) {
        this.vitalsType.deviceDefaultValuesJson = JSON.stringify(newValue);
      },
    },

    getUnitSystem () {
      return this.unitSystem;
    },

    headers () {
      const heads = [{ text: 'Date & Time', value: 'measuredAtFormatted',	class: 'table-header pl-4' }];
      switch (this.vitalsType.deviceType) {
        case 7:
          heads.push({ text: 'mg/dL', value: 'cholesterol_mgL',	class: 'table-header pl-5' });
          heads.push({ text: 'mmol/L', value: 'cholesterol_mmolL',	class: 'table-header pl-3' });
          break;

        case 6:
          heads.push({ text: 'mg/dL', value: 'glucose_mgL',	class: 'table-header pl-5' });
          heads.push({ text: 'mmol/L', value: 'glucose_mmolL',	class: 'table-header pl-3' });
          heads.push({ text: 'Before meal', value: 'isMeasuredBeforeMeal',	class: 'table-header pl-3' });
          // heads.push({ text: this.$t('glucose-value-befor-meal'), value: 'before_meal',	class: 'table-header pl-3' });
          break;

        case 3:
          heads.push({ text: 'Sys', value: 'Sys',	class: 'table-header pl-4' });
          heads.push({ text: 'Dias', value: 'Dias',	class: 'table-header pl-3' });
          heads.push({ text: 'Pulse', value: 'Pul',	class: 'table-header pl-2' });
          break;

        case 1:
          heads.push({ text: 'C°', value: 'Temperature_C',	class: 'table-header pl-6' });
          heads.push({ text: 'F°', value: 'Temperature_F',	class: 'table-header pl-6' });
          break;

        case 5:
          heads.push({ text: 'Kg', value: 'weight_kg',	class: 'table-header pl-6' });
          heads.push({ text: 'Lb', value: 'weight_lbs',	class: 'table-header pl-6' });
          break;

        case 2:
          heads.push({ text: 'Sp02', value: 'Spo2',	class: 'table-header pl-3' });
          heads.push({ text: 'Bpm', value: 'BPM',	class: 'table-header pl-3' });
          heads.push({ text: 'Pi', value: 'PI',	class: 'table-header pl-5' });
          break;

        default:
          break;
      }

      return heads;
    },

    measurements () {
      const values = [];
      const filteredDate = this.filterDate();
      this.vitalsType.measurementsData.forEach((data) => {
        const parsedData = JSON.parse(data.measuredDataJson);
        const measurementDate = new Date(data.measuredAt);
        // this.debug(this.filterDate);
        // this.debug(measurementDate);
        // measuredDataJson: '{"Temperature_C":36.899999999999999, "TemperatureBodyType":2,"Temperature_F":98.299999999999997,"device_id":"0aac55da-5102-4dd5-a770-129d4cc68d0a","device_type":"1"}'
        // measuredDataJson: '{"MeasurementStatus":1,"device_id":"C6:05:52:00:E8:02","device_type":"6","glucose_mgL":110.0,"glucose_mmolL":6.1,"time":"2023-02-20T09:53:51.523"}'
        if (measurementDate > filteredDate) {
          switch (parseInt(parsedData.device_type)) {
            case 1:
              parsedData.Temperature_C = parseFloat(parsedData.Temperature_C).toFixed(1);
              parsedData.Temperature_F = parseFloat(parsedData.Temperature_F).toFixed(1);
              break;
            case 2:
              parsedData.Spo2 = Number(parsedData.Spo2).toFixed();
              parsedData.PI = Number(parsedData.PI).toFixed(1);
              parsedData.BPM = Number(parsedData.BPM).toFixed();
              break;
            case 3:
              parsedData.Dias = Number(parsedData.Dias.toFixed());
              parsedData.Sys = Number(parsedData.Sys.toFixed());
              parsedData.Pul = Number(parsedData.Pul.toFixed(1));
              break;
            case 5:
              parsedData.weight_kg = Number.parseFloat(parsedData.weight_kg).toFixed(1);
              parsedData.weight_lbs = Number.parseFloat(parsedData.weight_lbs).toFixed(1);
              break;
            case 6:
              parsedData.glucose_mmolL = Number.parseFloat(parsedData.glucose_mmolL).toFixed(2);
              parsedData.glucose_mgL = Number.parseFloat(parsedData.glucose_mgL).toFixed(1);
              if (parsedData.isMeasuredBeforeMeal === true) {
                parsedData.isMeasuredBeforeMeal = this.$t('before');
              } else {
                parsedData.isMeasuredBeforeMeal = this.$t('after');
              }
              break;
            case 7:
              parsedData.cholesterol_mmolL = Number.parseFloat(parsedData.cholesterol_mmolL).toFixed(2);
              parsedData.cholesterol_mgL = Number.parseFloat(parsedData.cholesterol_mgL).toFixed(1);
              break;

            default:
              // Handle default case or unknown device_type
              // ...
              break;
          }
          parsedData.measuredAt = data.measuredAtUtc;
          parsedData.measuredAtFormatted = formatterFilter(
              data.measuredAtUtc,
              'datetime_med',
              this.timeLocalization,
              null,
          );
          values.push(parsedData);
        }
      });

      if (values.length === 0) {
        this.setNODataVariables(false);
        this.debug(['FilteredValues', values]);
      } else {
        this.setNODataVariables(true);
      }

      return values;
    },
  },
  methods: {
    async synchronizeDrawers (drawer, item) {
      if (drawer === 'addNote') {
        this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer);
      } else {
        this.$store.commit('SET_PATIENT_DETAILS_DRAWER', drawer, item);
        await this.$store.dispatch('appointments/getNotesById', item?.noteId);
      }
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', {
        updateDrawer: true,
        page2: 'patientdetails',
      });
    },
    getDataFilterType () {
      this.$store.dispatch('verifiers/getDataFilterTypes')
          .then(() => {
            this.period = this.$cookies.get('datafilter');
          });
    },
    async filterData () {
      // this.$emit('filter-changed', item);
      const data = {
        id: this.detailedUser.userData.id,
        filter: this.period,
      };
      await this.getHubsData(data);
    },

    async getHubsData (data) {
      await this.$store.dispatch('nosessionhubs/getHubsData', data).then(this.loading = false);
          var measurementDataOfSelectedDevice = this.hubs.assignedMedicalDevicesList.find(dataForDeviceType => dataForDeviceType.deviceType === this.vitalsType.deviceType);

          this.extreamThreshHolds = JSON.parse(measurementDataOfSelectedDevice.deviceDefaultExtremeValuesJson);
          this.thresholds = JSON.parse(measurementDataOfSelectedDevice.deviceDefaultValuesJson);

          this.vitalsType.measurementsData = measurementDataOfSelectedDevice.measurementsData;
    },
    toggleThresholds () {
      this.$refs.vitalsGraph.toggleThresholds();
    },
    closeDrawer () {
      this.$emit('close');

      // Check if vitalsGraph is defined before calling filterChange
      // if (this.$refs.vitalsGraph) {
      //   this.$refs.vitalsGraph.filterChange();
      // }
    },

    debug (data) {
    },

    setNODataVariables (state) {
      this.periodData = state;
    },

    filterChange () {
      this.rerender = !this.rerender;
      this.$nextTick(() => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs.vitalsGraph?.filterChange?.();
      });
    },

    filterDate () {
      const filter = this.period;
      const td = new Date();
      var filteredDate = null;
      switch (filter) {
        case 'Today':
          filteredDate = new Date(td.getFullYear(), td.getMonth(), td.getDate());
          break;

        case 'Last 7 days':
          filteredDate = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 6);
          break;

        case 'Last 14 days':
          filteredDate = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 13);
          break;

        case 'Last 30 days':
          filteredDate = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 29);
          break;
        default:
          filteredDate = new Date(td.getFullYear() - 1000, td.getMonth(), td.getDate());
          break;
      }

      return filteredDate;
    },

  },
};
</script>
<style>
.responsive-container {
  height: 80%;
  max-height: 350px; /* or any other max height you desire */
  overflow-x: hidden; /* add scrollbars if content exceeds the container height */
  overflow-y: auto; /* add scrollbars if content exceeds the container height */
}
label{
  font-size:13px !important;
}
</style>
