<template>
    <div class="d-flex align-end justify-center mr-3" ref="graphContainer" id="graphContainer" style="height: 100%;" >
        <v-container v-if="graphData !== null && graphData?.SurveyCategoryId == 27">
            <v-row>
                <v-col cols="12">
                    <apexchart type="line" height="500" :options="chartOptions1" :series="graphData.Question1.Series"></apexchart>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// https://apexcharts.com/vue-chart-demos/mixed-charts/line-column-area/
import Graph from 'vue-apexcharts';
// import convert from 'convert-units';

export default {
    props: {
        graphData: null,
    },
    components: {
        apexchart: Graph,
    },
    data: function () {
        return {
            series: [{
              name: 'Desktop',
              data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
            }],
            chartOptions1: {
                chart: {
                    height: 200,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                stroke: {
                    curve: 'straight',
                },
                title: {
                    text: this.graphData != null ? this.graphData.Question1.QuestionText + ' total score chart' : 'Question',
                    align: 'left',
                },
                xaxis: {
					type: 'datetime',
					labels: {
						show: true,
						style: {
							// fontSize: '0px',
						},
					},
					axisTicks: {
						show: true,
					},
					tooltip: {
						enabled: true,
					},
				},
                yaxis: {
                    title: {
                        text: 'Total Score',
                    },
                    min: this.graphData != null ? Math.min(...this.graphData.Question1.PossibleAnswers) : 0, // caluculate min value from array of possible answers
                    max: this.graphData != null ? Math.max(...this.graphData.Question1.PossibleAnswers) : 28, // caluculate max value from array of possible answers
                    stepSize: 1,
                    // decimalsInFloat: 0,
                    forceNiceScale: true,
				},
            },
        };
    },
    computed: {

    },
    async created () {
    },
    async mounted () {
    },
    methods: {

    },

};

</script>

<style>

</style>
